<template>
  <div>

    <div class="questions" v-if="state=='0'">
      <div class="liidi-question">
        <p>1. Millaiseen kohteeseen etsit hygieniatarvikkeita?</p>
      </div>
      <div class="liidi-valinta">
        <a href="javascript:void(0)" @click="gotoState('1.0')">
          <img src="/wp-content/uploads/2021/06/kat-toimisto.jpg">
          <span class="title">Toimisto</span>
        </a>
        <a href="javascript:void(0)" @click="gotoState('2.0')">
          <img src="/wp-content/uploads/2021/06/kat-paivakoti-600x429.jpg">
          <span class="title">Päiväkoti</span>
        </a>
        <a href="javascript:void(0)" @click="gotoState('3.0')">
          <img src="/wp-content/uploads/2021/06/kat-hoivakoti-600x429.jpg">
          <span class="title">Hoivakoti</span>
        </a>
        <a href="javascript:void(0)" @click="gotoState('4.0')">
          <img src="/wp-content/uploads/2021/06/kat-ravintola-600x375.jpg">
          <span class="title">Ravintola/baari</span>
        </a>
        <a href="javascript:void(0)" @click="gotoState('5.0')">
          <img src="/wp-content/uploads/2021/06/kat-teollisuus-600x454.jpg">
          <span class="title">Teollisuus</span>
        </a>
        <a href="javascript:void(0)" @click="gotoState('6.0')">
          <img src="/wp-content/uploads/2021/06/kat-hotelli-600x448.jpg">
          <span class="title">Hotelli/kylpylä</span>
        </a>
        <a href="javascript:void(0)" @click="gotoState('7.0')">
          <img src="/wp-content/uploads/2021/06/kat-kuntosali.jpg">
          <span class="title">Kuntosali</span>
        </a>
        <a href="/julkinen-sektori">
          <img src="/wp-content/uploads/2021/06/kaytto-kohtuullista.jpg">
          <span class="title">Julkinen sektori</span>
        </a>
        <!--
        <button @click="gotoState('1.0')">Toimisto</button>
        <button @click="gotoState('2.0')">Päiväkoti</button>
        <button @click="gotoState('3.0')">Hoivakoti</button>
        <button @click="gotoState('4.0')">Ravintola/baari</button>
        <button @click="gotoState('5.0')">Teollisuus</button>
        <button @click="gotoState('6.0')">Hotelli/kylpylä</button>
        <button @click="gotoState('7.0')">Kuntosali</button>
        <button @click="gotoState('8.F')">Julkinen sektori</button>-->
      </div>
    </div>

    <div class="questions" v-if="state=='1.0'">
      <h2>Toimisto</h2>
      <div class="liidi-question">
        Meillä on tarjolla juuri sopivia paketteja toimistokäyttöön. Vastaathan muutamaan kysymykseen, niin voimme
        laskea alustavan tarjouksen.
      </div>
      <div class="liidi-question">
        <p>2. Henkilöstön lukumäärä:</p>
      </div>
      <div class="liidi-answer">
        <label><input type="radio" v-model="answers['amount']" value="alle 5">alle 5</label>
        <label><input type="radio" v-model="answers['amount']" value="5-25">5-25</label>
        <label><input type="radio" v-model="answers['amount']" value="yli 25">yli 25</label>
      </div>
      <button @click="gotoState('1.1')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='1.1'">
      <h2>Toimisto</h2>
      <div class="liidi-question">
        <p>3. WC-tilojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
      </div>
      <div class="liidi-answer">
        <input type="text" name="wc_amount" v-model="answers['wc_amount']">
      </div>
      <button @click="gotoState('1.0')">Edellinen kysymys</button>
      <button @click="gotoState('1.2')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='1.2'">
      <h2>Toimisto</h2>
      <div class="liidi-question">
        <p>4. Mitä muita tiloja löytyy (valitse sopivat vaihtoehdot):</p>
      </div>
      <div class="liidi-answer">
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Suihkutila">Suihkutila</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Henkilökunnan sosiaalitilat">Henkilökunnan
          sosiaalitilat</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Sauna">Sauna</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Taukotila">Taukotila</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Keittiö">Keittiö</label>
      </div>
      <button @click="gotoState('1.1')">Edellinen kysymys</button>
      <button @click="gotoState('1.F')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='1.F'">
      <a href="javascript:void(0)" @click="gotoState('1.2')">edellinen kysymys</a>
      <p>
        Kiitos tiedoista! Jätäthän vielä yhteystietosi, niin olemme sinuun yhteydessä tarjouksen tiimoilta. Voit myös
        jättää tarkemmat tiedot esitäytetyn lomakkeen kautta.
      </p>
    </div>


    <div class="questions" v-if="state=='2.0'">
      <h2>Päiväkoti</h2>
      <div class="liidi-question">
        Meillä on tarjolla juuri sopivia paketteja päiväkotikäyttöön. Vastaathan muutamaan kysymykseen, niin voimme
        laskea alustavan tarjouksen.
      </div>
      <div class="liidi-question">
        <p>1. Ryhmien lukumäärä:</p>
      </div>
      <div class="liidi-answer">
        <label><input type="radio" v-model="answers['amount']" value="alle 5">alle 5</label>
        <label><input type="radio" v-model="answers['amount']" value="5-10">5-10</label>
        <label><input type="radio" v-model="answers['amount']" value="yli 10">yli 10</label>
      </div>
      <button @click="gotoState('2.1')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='2.1'">
      <h2>Päiväkoti</h2>
      <div class="liidi-question">
        <p>2. WC-tilojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
      </div>
      <div class="liidi-answer">
        <input type="text" name="wc_amount" v-model="answers['wc_amount']">
      </div>
      <button @click="gotoState('2.0')">Edellinen kysymys</button>
      <button @click="gotoState('2.2')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='2.2'">
      <h2>Päiväkoti</h2>
      <div class="liidi-question">
        <p>3. Mitä muita tiloja löytyy (valitse sopivat vaihtoehdot):</p>
      </div>
      <div class="liidi-answer">
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Suihkutila">Suihkutila</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Taukotila">Taukotila</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Keittiö">Keittiö</label>
      </div>
      <button @click="gotoState('2.1')">Edellinen kysymys</button>
      <button @click="gotoState('2.F')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='2.F'">
      <h2>Päiväkoti</h2>
      <a href="javascript:void(0)" @click="gotoState('2.2')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <p>
        Kiitos tiedoista! Jätäthän vielä yhteystietosi, niin olemme sinuun yhteydessä tarjouksen tiimoilta. Voit myös
        jättää tarkemmat tiedot esitäytetyn lomakkeen kautta.
      </p>
    </div>


    <div class="questions" v-if="state=='3.0'">
      <h2>Hoivakoti</h2>
      <div class="liidi-question">
        Meillä on tarjolla juuri sopivia paketteja hoivakotikäyttöön. Vastaathan vielä yhteen kysymykseen, niin voimme
        laskea alustavan tarjouksen.
      </div>
      <div class="liidi-question">
        <p>2. Asukaspaikkojen määrä:</p>
      </div>
      <div class="liidi-answer">
        <label><input type="radio" v-model="answers['amount']" value="alle 25">alle 25</label>
        <label><input type="radio" v-model="answers['amount']" value="25-5">25-50</label>
        <label><input type="radio" v-model="answers['amount']" value="50-100">50-100</label>
        <label><input type="radio" v-model="answers['amount']" value="yli 100">yli 100</label>
      </div>
      <button @click="gotoState('3.1')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='3.1'">
      <h2>Hoivakoti</h2>
      <div class="liidi-question">
        <p>3. WC-tilojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
      </div>
      <div class="liidi-answer">
        <input type="text" name="wc_amount" v-model="answers['wc_amount']">
      </div>
      <button @click="gotoState('3.0')">Edellinen kysymys</button>
      <button @click="gotoState('3.2')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='3.2'">
      <h2>Hoivakoti</h2>
      <div class="liidi-question">
        <p>4. Mitä muita tiloja löytyy (valitse sopivat vaihtoehdot):</p>
      </div>
      <div class="liidi-answer">
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Allasosasto">Allasosasto</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Suihkutila">Suihkutila</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Henkilökunnan sosiaalitilat">Henkilökunnan
          sosiaalitilat</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Sauna">Sauna</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Taukotila">Taukotila</label>
        <label><input type="checkbox" v-model="answers['other_spaces']" value="Keittiö">Keittiö</label>
      </div>
      <button @click="gotoState('3.1')">Edellinen kysymys</button>
      <button @click="gotoState('3.F')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='3.F'">
      <h2>Hoivakoti</h2>
      <a href="javascript:void(0)" @click="gotoState('3.2')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <p>
        Kiitos tiedosta! Jätäthän vielä yhteystietosi, niin olemme sinuun yhteydessä tarjouksen tiimoilta. Voit myös
        jättää tarkemmat tiedot esitäytetyn lomakkeen kautta.
      </p>
    </div>


    <div class="questions" v-if="state=='4.0'">
      <h2>Ravintola/baari</h2>
      <div class="liidi-question">
        Meillä on tarjolla juuri sopivia paketteja hoivakotikäyttöön. Vastaathan vielä yhteen kysymykseen, niin voimme
        laskea alustavan tarjouksen.
      </div>
      <div class="liidi-question">
        <p>1. Asiakaspaikat:</p>
      </div>
      <div class="liidi-answer">
        <label><input type="radio" v-model="answers['amount']" value="alle 25">alle 25</label>
        <label><input type="radio" v-model="answers['amount']" value="25-50">25-50</label>
        <label><input type="radio" v-model="answers['amount']" value="50-100">50-100</label>
        <label><input type="radio" v-model="answers['amount']" value="yli 100">yli 100</label>
      </div>
      <button @click="gotoState('4.1')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='4.1'">
      <h2>Ravintola/baari</h2>
      <div class="liidi-question">
        <p>2. Onko ravintolassa/baarissa keittiö?</p>
      </div>
      <div class="liidi-answer">
        <label><input type="radio" v-model="answers['boolean']" value="Kyllä">Kyllä</label>
        <label><input type="radio" v-model="answers['boolean']" value="Ei">Ei</label>
      </div>
      <button @click="gotoState('4.0')">Edellinen kysymys</button>
      <button @click="gotoState('4.2')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='4.2'">
      <h2>Ravintola/baari</h2>
      <div class="liidi-question">
        <p>3. WC-tilojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
      </div>
      <div class="liidi-answer">
        <input type="text" name="wc_amount" v-model="answers['wc_amount']">
      </div>
      <button @click="gotoState('4.1')">Edellinen kysymys</button>
      <button @click="gotoState('4.F')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='4.F'">
      <h2>Ravintola/baari</h2>
      <a href="javascript:void(0)" @click="gotoState('4.2')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <p>
        Kiitos tiedosta! Jätäthän vielä yhteystietosi, niin olemme sinuun yhteydessä tarjouksen tiimoilta. Voit myös
        jättää tarkemmat tiedot esitäytetyn lomakkeen kautta.
      </p>
    </div>
  </div>


  <div class="questions" v-if="state=='5.0'">
    <h2>Teollisuus</h2>
    <div class="liidi-question">
      Meillä on tarjolla juuri sopivia paketteja teollisuuskäyttöön. Vastaathan vielä yhteen kysymykseen, niin voimme
      laskea alustavan tarjouksen.
    </div>
    <div class="liidi-question">
      <p>2. Henkilökunnan lukumäärä:</p>
    </div>
    <div class="liidi-answer">
      <label><input type="radio" v-model="answers['amount']" value="alle 5">alle 5</label>
      <label><input type="radio" v-model="answers['amount']" value="5-25">5-25</label>
      <label><input type="radio" v-model="answers['amount']" value="25-5">25-50</label>
      <label><input type="radio" v-model="answers['amount']" value="yli 50">yli 50</label>
    </div>
    <button @click="gotoState('5.1')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='5.1'">
    <h2>Teollisuus</h2>
    <div class="liidi-question">
      <p>3. WC-tilojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
    </div>
    <div class="liidi-answer">
      <input type="text" name="wc_amount" v-model="answers['wc_amount']">
    </div>
    <button @click="gotoState('5.0')">Edellinen kysymys</button>
    <button @click="gotoState('5.2')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='5.2'">
    <h2>Teollisuus</h2>
    <div class="liidi-question">
      <p>4. Mitä muita tiloja löytyy (valitse sopivat vaihtoehdot):</p>
    </div>
    <div class="liidi-answer">
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Lounasravintola">Lounasravintola</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Suihkutila">Suihkutila</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Henkilökunnan sosiaalitilat">Henkilökunnan
        sosiaalitilat</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Sauna">Sauna</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Kylpylä">Kylpylä</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Keittiö">Keittiö</label>
    </div>
    <button @click="gotoState('5.1')">Edellinen kysymys</button>
    <button @click="gotoState('5.3')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='5.3'">
    <h2>Teollisuus</h2>
    <div class="liidi-question">
      <p>5. Vesipisteiden lukumäärä. Jätä tyhjäksi, jos ei tiedossa:</p>
    </div>
    <div class="liidi-answer">
      <input type="text" name="water_amount" v-model="answers['water_amount']">
    </div>
    <button @click="gotoState('5.2')">Edellinen kysymys</button>
    <button @click="gotoState('5.F')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='5.F'">
    <h2>Teollisuus</h2>
    <a href="javascript:void(0)" @click="gotoState('5.3')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
    <p>
      Kiitos tiedosta! Jätäthän vielä yhteystietosi, niin olemme sinuun yhteydessä tarjouksen tiimoilta. Voit myös
      jättää tarkemmat tiedot esitäytetyn lomakkeen kautta.
    </p>
  </div>


  <div class="questions" v-if="state=='6.0'">
    <h2>Hotelli/kylpylä</h2>
    <div class="liidi-question">
      Meillä on tarjolla juuri sopivia paketteja hotelleille ja kylpylöille. Vastaathan vielä muutamaan kysymykseen,
      niin voimme laskea alustavan tarjouksen.
    </div>
    <div class="liidi-question">
      <p>2. Huoneiden lukumäärä:</p>
    </div>
    <div class="liidi-answer">
      <input type="text" name="amount" v-model="answers['amount']">
    </div>
    <button @click="gotoState('6.1')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='6.1'">
    <h2>Hotelli/kylpylä</h2>
    <div class="liidi-question">
      <p>3. WC-tilojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
    </div>
    <div class="liidi-answer">
      <input type="text" name="wc_amount" v-model="answers['wc_amount']">
    </div>
    <button @click="gotoState('6.0')">Edellinen kysymys</button>
    <button @click="gotoState('6.2')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='6.2'">
    <h2>Hotelli/kylpylä</h2>
    <div class="liidi-question">
      <p>4. Saunojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
    </div>
    <div class="liidi-answer">
      <input type="text" name="sauna_amount" v-model="answers['sauna_amount']">
    </div>
    <button @click="gotoState('6.1')">Edellinen kysymys</button>
    <button @click="gotoState('6.3')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='6.3'">
    <h2>Hotelli/kylpylä</h2>
    <div class="liidi-question">
      <p>5. Suihkutilojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
    </div>
    <div class="liidi-answer">
      <input type="text" name="suihku_amount" v-model="answers['suihku_amount']">
    </div>
    <button @click="gotoState('6.2')">Edellinen kysymys</button>
    <button @click="gotoState('6.4')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='6.4'">
    <h2>Hotelli/kylpylä</h2>
    <div class="liidi-question">
      <p>6. Mitä muita tiloja löytyy (valitse sopivat vaihtoehdot):</p>
    </div>
    <div class="liidi-answer">
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Kuntosali">Kuntosali</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Ravintola">Ravintola</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Henkilökunnan sosiaalitilat">Henkilökunnan
        sosiaalitilat</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Kylpylä">Kylpylä</label>
      <label><input type="checkbox" v-model="answers['other_spaces']" value="Keittiö">Keittiö</label>
    </div>
    <button @click="gotoState('6.3')">Edellinen kysymys</button>
    <button @click="gotoState('6.F')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='6.F'">
    <h2>Hotelli/kylpylä</h2>
    <a href="javascript:void(0)" @click="gotoState('6.2')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
    <p>
      Kiitos tiedoista! Jätäthän vielä yhteystietosi, niin olemme sinuun yhteydessä tarjouksen tiimoilta. Voit myös
      jättää tarkemmat tiedot esitäytetyn lomakkeen kautta.
    </p>
  </div>


  <div class="questions" v-if="state=='7.0'">
    <h2>Kuntosali</h2>
    <div class="liidi-question">
      Meillä on tarjolla juuri sopivia paketteja kuntosalien- ja keskusten tarpeisiin. Vastaathan vielä muutamaan
      kysymykseen, niin voimme laskea alustavan tarjouksen.
    </div>
    <div class="liidi-question">
      <p>2. Kuinka monta asiakaskäyntiä kuukaudessa:</p>
    </div>
    <div class="liidi-answer">
      <label><input type="radio" v-model="answers['amount']" value="500">500</label>
      <label><input type="radio" v-model="answers['amount']" value="yli 5000">yli 5000</label>
      <label><input type="radio" v-model="answers['amount']" value="yli 10 000">yli 10 000</label>
    </div>
    <button @click="gotoState('7.1')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='7.1'">
    <h2>Kuntosali</h2>
    <div class="liidi-question">
      <p>3. Pukuhuoneiden lukumäärä:</p>
    </div>
    <div class="liidi-answer">
      <label><input type="radio" v-model="answers['amount1']" value="1">1</label>
      <label><input type="radio" v-model="answers['amount1']" value="2">2</label>
      <label><input type="radio" v-model="answers['amount1']" value="3">3</label>
      <label><input type="radio" v-model="answers['amount1']" value="4">4</label>
      <label><input type="radio" v-model="answers['amount1']" value="Enemmän kuin 4">Enemmän kuin 4</label>
      <label><input type="radio" v-model="answers['amount1']" value="Ei ole">Ei ole</label>
    </div>
    <button @click="gotoState('7.0')">Edellinen kysymys</button>
    <button @click="gotoState('7.2')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='7.2'">
    <h2>Kuntosali</h2>
    <div class="liidi-question">
      <p>4. Saunojen lukumäärä:</p>
    </div>
    <div class="liidi-answer">
      <label><input type="radio" v-model="answers['amount2']" value="1">1</label>
      <label><input type="radio" v-model="answers['amount2']" value="2">2</label>
      <label><input type="radio" v-model="answers['amount2']" value="3">3</label>
      <label><input type="radio" v-model="answers['amount2']" value="4">4</label>
      <label><input type="radio" v-model="answers['amount2']" value="Enemmän kuin 4">Enemmän kuin 4</label>
      <label><input type="radio" v-model="answers['amount2']" value="Ei ole">Ei ole</label>
    </div>
    <button @click="gotoState('7.1')">Edellinen kysymys</button>
    <button @click="gotoState('7.3')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='7.3'">
    <h2>Kuntosali</h2>
    <div class="liidi-question">
      <p>5. Suihkutilojen lukumäärä:</p>
    </div>
    <div class="liidi-answer">
      <label><input type="radio" v-model="answers['amount3']" value="1">1</label>
      <label><input type="radio" v-model="answers['amount3']" value="2">2</label>
      <label><input type="radio" v-model="answers['amount3']" value="3">3</label>
      <label><input type="radio" v-model="answers['amount3']" value="4">4</label>
      <label><input type="radio" v-model="answers['amount3']" value="Enemmän kuin 4">Enemmän kuin 4</label>
      <label><input type="radio" v-model="answers['amount3']" value="Ei ole">Ei ole</label>
    </div>
    <button @click="gotoState('7.2')">Edellinen kysymys</button>
    <button @click="gotoState('7.4')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='7.4'">
    <h2>Kuntosali</h2>
    <div class="liidi-question">
      <p>6. WC-tilojen lukumäärä: (Jätä tyhjäksi, jos ei tiedossa)</p>
    </div>
    <div class="liidi-answer">
      <label><input type="radio" v-model="answers['wc_amount']" value="1">1</label>
      <label><input type="radio" v-model="answers['wc_amount']" value="2">2</label>
      <label><input type="radio" v-model="answers['wc_amount']" value="3">3</label>
      <label><input type="radio" v-model="answers['wc_amount']" value="4">4</label>
      <label><input type="radio" v-model="answers['wc_amount']" value="Enemmän kuin 4">Enemmän kuin 4</label>
      <label><input type="radio" v-model="answers['wc_amount']" value="Ei ole">Ei ole</label>
    </div>
    <button @click="gotoState('7.3')">Edellinen kysymys</button>
    <button @click="gotoState('7.F')">Jatka</button>
  </div>
  <div class="questions" v-if="state=='7.F'">
    <h2>Kuntosali</h2>
    <a href="javascript:void(0)" @click="gotoState('7.4')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
    <p>
      Kiitos tiedoista! Jätäthän vielä yhteystietosi, niin olemme sinuun yhteydessä tarjouksen tiimoilta. Voit myös
      jättää tarkemmat tiedot esitäytetyn lomakkeen kautta.
    </p>
  </div>

  <div class="questions" v-if="state=='8.F'">
    <h2>Julkinen sektori</h2>
    <div class="liidi-question">
      Jätä yhteystietosi tästä, niin lähetämme sinulle julkisen sektorin sopimushinnaston.
    </div>
  </div>

</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      state: '0',
      answers: {
        'amount': '',
        'amount1': '',
        'amount2': '',
        'amount3': '',
        'amount4': '',
        'wc_amount': '',
        'sauna_amount': '',
        'suihku_amount': '',
        'water_amount': '',
        'boolean': '',
        'other_spaces': [],

      },

    }
  },
  mounted() {
    let firstState = document.getElementById('app').dataset.state;
    if (firstState) {
      this.state = firstState;
    }
    this.hideForms();
  },
  methods: {
    gotoState(newState) {
      this.updateData();

      this.state = newState;

      //hide old
      this.hideForms();
      if (newState != '0') {
        let element = document.getElementById('liidilomake-' + newState);
        if (element) {
          element.style.display = 'block';
        }

      }
    },
    updateData() {
      this.setFormFields('data-otherSpaces', this.answers['other_spaces'].join(', '));
      this.setFormFields('data-amount', this.answers['amount']);
      this.setFormFields('data-wcAmount', this.answers['wc_amount']);
      this.setFormFields('data-saunaAmount', this.answers['sauna_amount']);
      this.setFormFields('data-suihkuAmount', this.answers['suihku_amount']);
      this.setFormFields('data-waterAmount', this.answers['water_amount']);
      this.setFormFields('data-amount1', this.answers['amount1']);
      this.setFormFields('data-amount2', this.answers['amount2']);
      this.setFormFields('data-amount3', this.answers['amount3']);
      this.setFormFields('data-amount4', this.answers['amount4']);

    },
    setFormFields(className, value) {
      if (typeof value == 'undefined') {
        value = '';
      }
      document.getElementsByClassName(className).forEach(function (item) {
        item.value = value;
      });
    },
    hideForms() {
      document.getElementsByClassName('liidilomake').forEach(function (item) {

        item.style.display = 'none';
      });
    },

  }
}
</script>

<style>

</style>
